import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Col, Container, Row } from 'react-bootstrap'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Content from './style'
import SectionTitle from './Components/SectionTitle'
import Data from '~data-yaml/home.yml'
import { Link } from "~components"

const ContentSectionOne =() => {
  const iName = Data.contentSectionOne.image
  const data = useStaticQuery(graphql`
  query ContentImage($image: String ! = "/home-agency/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
`)

const iData = (name) => {
  let iDat = undefined
  for (let i = 0; i<data.allFile.edges.length; i++) {
    if (data.allFile.edges[i].node.base == name) {
      iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
    }
  }
  return iDat
}
  return (
    <Content>
      <Container>
        <Content.InnerWrapper>
          <Row className="align-items-center justify-content-center">
            <Col xs="12" className="col-lg-4 col-md-8 col-xs-11 text-center text-lg-start">
              {/* Section Title */}
              <SectionTitle
                subTitle={Data.contentSectionOne.subtitle}
                title={Data.contentSectionOne.title}
                text={Data.contentSectionOne.text}
                titleProps={{ mb: "30px" }}
                subTitleProps={{ mb: "25px" }}
              />
              <Content.Button background="#ff5722" rounded={true} mt="25px" as={Link} to={Data.contentSectionOne.link_learn}>{Data.contentSectionOne.label_learn}
              </Content.Button>
            </Col>
            <Col xs="12" className="col-xxl-5 col-lg-4 col-md-6 col-xs-9">
              <Content.Image mt="30px" mb="30px" mbMD="0" mtMD="0">
                <GatsbyImage 
                image={iData(iName)} 
                alt="content" 
                layout="fullWidth" 
                placeholder="blurred" />
              {/* <img src={iName} alt="image" /> */}
              </Content.Image>
            </Col>
            <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-9 order-3">
              <Content.WidgetsWrapper mb="-10px" mtMD="40px" mtLG="0" mrXS="70px" mrMD="30px" mrLG="0">
                {Data.contentSectionOne.content.map((item, i) => {
                  return (
                    <Content.Widget pb="10px" key={"so" + i}>
                      <Content.WidgetTitle>{item.title}</Content.WidgetTitle>
                      <Content.WidgetText>{item.text}</Content.WidgetText>
                    </Content.Widget>
                  )
                })}
              </Content.WidgetsWrapper>
            </Col>
          </Row>
        </Content.InnerWrapper>
      </Container>
    </Content>

  )
}

export default ContentSectionOne