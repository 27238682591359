import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components';
import About from './style'
import SectionTitle from './Components/SectionTitle'
import Video from '~components/VideoModal'
import Data from '~data-yaml/home.yml'

const AboutSection = () => {
  const iName = Data.about.image
  const data = useStaticQuery(graphql`
  query homeAboutImage($image: String ! = "/home-agency/") {
      allFile(filter: {relativePath: {regex: $image}}) {
        edges {
          node {
            base
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      dataYamlYaml(page: {eq: "home"}) {
        about {
          text
        }
      }
    }
`)

const iData = (name) => {
  let iDat = undefined
  for (let i = 0; i<data.allFile.edges.length; i++) {
    if (data.allFile.edges[i].node.base == name) {
      iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
    }
  }
  return iDat
}

return(
  <About>
  <Container>
    {/* about-us Content */}
    <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <About.Image>
          <GatsbyImage className="w-100"
            image={iData(iName)} 
            alt='about'
          />
         {/* <Video id={Data.about.video} className="video-btn sonar-emitter"> <i className="fa fa-play" /> </Video>*/}
        </About.Image>
      </Col>
      <Col col="12" className="col-xxl-5 offset-xxl-1 col-lg-5 col-md-8 col-xs-10">
        <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
              <SectionTitle 
                subTitle={Data.about.subtitle}
                title={Data.about.title}
                text={data.dataYamlYaml.about.text}
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />
          <About.ButtonGroup mt="25px">
            {/*  <About.Button className="btn-primary btn-1" as={Link} to={Data.about.link_learn}>{Data.about.label_learn}</About.Button> */}
            <About.Button className="btn-electric-violet-2 btn-2"  as={Link} to={Data.about.link_cta}>{Data.about.label_cta}</About.Button>
          </About.ButtonGroup>
        </About.Content>
      </Col>
    </Row>
    {/*/ .about-us Content */}
  </Container>
</About>

)
}
export default AboutSection